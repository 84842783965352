import Vue from "vue/dist/vue.esm";
import axios from "axios";
import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { getSunrise, getSunset } from "sunrise-sunset-js";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import Toasted from "vue-toasted";

Vue.component("calendar", Calendar);
Vue.component("date-picker", DatePicker);

let token = document.getElementsByName("csrf-token")[0].getAttribute("content");
axios.defaults.headers.common["X-CSRF-Token"] = token;
axios.defaults.headers.common["Accept"] = "application/json";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector("#substationCalendar");
  let currentYear = new Date().getFullYear();

  if (el != null) {
    const substation_id = el.dataset.substationId;
    const coordinates = JSON.parse(el.dataset.coordinates);
    const today = new Date();

    Vue.use(Toasted);

    const app = new Vue({
      el: el,
      components: {
        Calendar,
        DatePicker,
        VueTimepicker
      },
      data: function() {
        return {
          substationId: substation_id,
          coordinates: coordinates,
          dayEvents: {},
          pickedDay: today,
          daySchedule: [],
          formEvent: {},
          changedParts: [],
          mode: "light",
          size: {
            columns: 3,
            rows: 4
          },
          startPage: {
            month: 1,
            year: today.getFullYear()
          },
          currentYear: today.getFullYear(),
          attrs: [
            {
              key: "today",
              highlight: "blue",
              dates: today
            }
          ]
        };
      },
      computed: {
        humanPickedDay() {
          return this.pickedDay.toLocaleDateString("ru-RU");
        },
        showForm() {
          return Object.keys(this.formEvent).length !== 0;
        },
        eventCreating() {
          return (
            Object.keys(this.formEvent).length !== 0 &&
            !this.formEvent.id &&
            !this.formEvent.is_default
          );
        }
      },
      mounted: function() {
        this.onResize();
        this.$nextTick(() => {
          window.addEventListener("resize", this.onResize);
        });

        let vm = this;
        axios
          .get(`/api/backend/substations/${vm.substationId}/events`)
          .then(function(resp) {
            vm.dayEvents = resp.data;
            vm.setSchedule(today);
          })
          .catch(function(error) {
            console.log(error);
          });
      },
      methods: {
        clickDay(e) {
          this.pickedDay = e.date;
          this.setSchedule();
          this.formEvent = {};
        },
        setSchedule() {
          let de = this.dayEvents[this.humanPickedDay] || [];

          if (de.find(i => i.is_main)) {
            this.daySchedule = de;
          } else {
            this.daySchedule = [this.defaultDay()].concat(de);
          }
        },
        defaultDay() {
          return {
            on: this.formatTime(
              getSunset(
                this.coordinates[0],
                this.coordinates[1],
                this.pickedDay
              )
            ),
            off: this.formatTime(
              getSunrise(
                this.coordinates[0],
                this.coordinates[1],
                this.pickedDay
              )
            ),
            is_default: true
          };
        },
        moveYear(date) {
          this.currentYear = date.year;
        },
        onResize() {
          let ww = window.innerWidth;
          if (ww < 576) {
            this.size = { columns: 1, rows: 12 };
          } else if (ww < 992) {
            this.size = { columns: 2, rows: 6 };
          } else {
            this.size = { columns: 3, rows: 4 };
          }
        },
        formatTime(dt) {
          let hours = dt.getHours() < 10 ? `0${dt.getHours()}` : dt.getHours();
          let minutes =
            dt.getMinutes() < 10 ? `0${dt.getMinutes()}` : dt.getMinutes();
          return `${hours}:${minutes}`;
        },
        editEvent(ev) {
          if (this.eventEditing(ev)) {
            this.formEvent = {};
          } else {
            this.formEvent = ev;
            this.formEvent.is_main = ev.is_default ? true : ev.is_main;
          }
        },
        eventEditing(ev) {
          return (
            (!!this.formEvent.id && this.formEvent.id == ev.id) ||
            (!!this.formEvent.is_default &&
              this.formEvent.is_default == ev.is_default)
          );
        },
        addEvent() {
          this.formEvent = {
            on: "12:00",
            off: "13:00",
            is_main: false
          };
        },
        clearEvent() {
          this.formEvent = {};
        },
        sendEvent() {
          if (confirm("Отправить событие?")) {
            !!this.formEvent.id ? this.updateEvent() : this.createEvent();
          }
        },
        createEvent() {
          let vm = this;
          axios
            .post(`/api/backend/substations/${vm.substationId}/events`, {
              day_event: {
                date: vm.humanPickedDay,
                on_time: `${vm.humanPickedDay} ${vm.formEvent.on}`,
                off_time: `${vm.humanPickedDay} ${vm.formEvent.off}`,
                is_main: vm.formEvent.is_main
              }
            })
            .then(function(resp) {
              vm.updateData(resp.data);
              vm.showToast("Событие добавлено");
            })
            .catch(function(error) {
              console.log(error);
              vm.showToast("Ошибка", "error");
            });
        },
        updateEvent() {
          let vm = this;
          axios
            .patch(
              `/api/backend/substations/${vm.substationId}/events/${vm.formEvent.id}`,
              {
                day_event: {
                  on_time: `${vm.humanPickedDay} ${vm.formEvent.on}`,
                  off_time: `${vm.humanPickedDay} ${vm.formEvent.off}`,
                  is_main: vm.formEvent.is_main
                }
              }
            )
            .then(function(resp) {
              vm.updateData(resp.data);
              vm.showToast("Событие обновлено");
            })
            .catch(function(error) {
              console.log(error);
              vm.showToast("Ошибка", "error");
            });
        },
        destroyEvent(ev) {
          if (confirm("Удалить событие?")) {
            let vm = this;
            axios
              .delete(
                `/api/backend/substations/${vm.substationId}/events/${ev.id}`
              )
              .then(function(resp) {
                vm.updateData(resp.data);
                vm.showToast("Событие удалено");
              })
              .catch(function(error) {
                console.log(error);
                vm.showToast("Ошибка", "error");
              });
          }
        },
        updateData(data) {
          Object.assign(this.dayEvents, data);
          this.setSchedule();
          this.attrs.push({
            key: this.humanPickedDay,
            dot: "red",
            dates: this.pickedDay
          });
          this.addPart();
          this.formEvent = {};
        },
        addPart() {
          let dayOfYear = Math.floor(
            (this.pickedDay - new Date(this.pickedDay.getFullYear(), 0, 0)) / 1000 / 60 / 60 / 24
          );
          let part = Math.ceil(dayOfYear / 10);
          if (this.changedParts.findIndex(i => i == part) == -1) {
            this.changedParts.push(part);
          }
        },
        sendSchedule() {
          if (confirm("Отправить расписание на УСПД?")) {
            let vm = this;
            axios
              .get(
                `/api/backend/substations/${vm.substationId}/events/send_schedule`, {
                  params: { parts: this.changedParts }
              })
              .then(function(resp) {
                vm.changedParts = [];
                vm.attrs = [{ key: "today", highlight: "blue", dates: today }];
                vm.showToast("Расписание отправлено на УСПД");
              })
              .catch(function(error) {
                console.log(error);
                vm.showToast("Ошибка", "error");
              });
          }
        },
        showToast(mess, type = "success") {
          Vue.toasted.show(mess, {
            theme: "toasted-primary",
            type: type,
            position: "bottom-right",
            duration: 3500
          });
        }
      }
    });
  }
});
