import Vue from "vue/dist/vue.esm";

import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector("#day_event_form");

  if (el != null) {
    const today = new Date();
    today.setHours(0,0,0,0);
    const date = el.dataset.date;
    const on_time = el.dataset.onTime || '';
    const off_time = el.dataset.offTime || '';

    const app = new Vue({
      el: el,
      components: {
        VueTimepicker,
        DatePicker
      },
      data: function() {
        return {
          onTime: {
            HH: on_time.split(':')[0],
            mm: on_time.split(':')[1]
          },
          offTime: {
            HH: off_time.split(':')[0],
            mm: off_time.split(':')[1]
          },
          date: date
        };
      },
      computed: {
        onDatetime() {
          return !!this.date && !!this.onTime
            ? `${this.date} ${this.onTime.HH}:${this.onTime.mm}`
            : "";
        },
        offDatetime() {
          return !!this.date && !!this.offTime
            ? `${this.date} ${this.offTime.HH}:${this.offTime.mm}`
            : "";
        },
        lang() {
          return {
            defaultLocale: "ru",
            firstDayOfWeek: 1
          };
        }
      },
      methods: {
        notBeforeToday(date) {
          return date < today;
        }
      }
    });
  }
});
