import "../src/styles.scss";

require("@rails/ujs").start();
// require("turbolinks").start()
// require("@rails/activestorage").start()
require("channels");

global.$ = require("jquery");

import "popper.js";
import "bootstrap";

global.toastr = require("toastr");
toastr.options = {
  "closeButton": true,
  "debug": false,
  "newestOnTop": false,
  "progressBar": false,
  "positionClass": "toast-top-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "500",
  "timeOut": "4000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

// import "select2";
// import "select2/dist/css/select2.css";
// import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css";

import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
import "bootstrap-datepicker/js/locales/bootstrap-datepicker.ru.js";

import "../vue_apps/calendar.js";
import "../vue_apps/substation_calendar.js";
import "../vue_apps/day_event_form.js";
import "../vue_apps/date_range_picker.js";
import "../vue_apps/filters_date_range_picker.js";
import "../vue_apps/export_date_range_picker";

$(document).ready(function() {
//   $(".select2-autocomplete").select2({
//     theme: "bootstrap4"
//   });
//
  $(".datepicker").datepicker({
    format: "yyyy-mm-dd",
    language: "ru",
    clearBtn: true
  });
});

$(function() {
  $('[data-toggle="tooltip"]').tooltip();
});

function onDocumentReady() {
  $(function() {
    $('[data-toggle="popover"]').popover();
  });
}

$(onDocumentReady);
