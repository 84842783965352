import consumer from "./consumer";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector("#uspdDeviceWs");

  if (el != null) {
    const uspd_device_id = el.dataset.uspdDeviceId;
    const user_id = el.dataset.userId;
    const mode = el.dataset.mode;
    const manage_id = el.dataset.manageId;

    consumer.subscriptions.create(
      { channel: "UspdDeviceChannel", uspd_device_id: uspd_device_id },
      {
        connected: function() {
          console.log(`connected uspd #${uspd_device_id}`);
        },
        disconnected: function() {
          console.log(`disconnected uspd #${uspd_device_id}`);
        },
        rejected: function() {
          console.log("rejected, no access");
        },
        received: function(data) {
          this.show_spinner();
          this[data.event](data.data);
        },

        update_status: function(data) {
          this.update_device_status("#mainStatus", data);
          data.meters.forEach(
            m => this.update_device_status(`#metersTable [data-meter-id="${m.id}"] .status-cell`, m)
          );
          if (data.status == 'Оффлайн') {
            $(".online-content").hide();
            $(".offline-content").show();
          } else {
            $(".offline-content").hide();
            $(".online-content").show();
          }
        },
        update_device_status: function(statusEl, data) {
          $(statusEl).html(
            `<span class='badge ${data.status_badge}'>${data.status}</span>
            <div class='text-muted small'>${data.status_at}</div>`
          )
        },
        update_mode: function(data) {
          $("#modeStatus").html(
            `<div class='${data.mode_color}'>
              <span class='h4 ${data.mode_icon}'></span>
              <span class='ml-2'>${data.mode}</span>
            </div>`);
          $("#lightStatus").html(lightStatusEl(data.light_on));
          $("#lockedStatus").html(lockedStatusEl(data.is_locked));
          if (!data.is_locked) {
            $("#controllerInfo").empty();
          } else {
            $("#controllerInfo").html(
              controllerEl(data.controller_id, data.controller_name)
            )
          }
        },
        update_request: function(data) {
          $(`#requestsTable [data-request-id="${data.id}"] .status-cell`).html(
            `<span class='badge ${data.status_badge}'>${data.status}</span>`
          );
          if (data.status != 'Pending') {
            $(`#requestsTable [data-request-id="${data.id}"] .cancel-link`).remove();
          }
        },
        new_request: function(data) {
          $("#requestsTable tbody tr").last().hide(300, function() { $(this).remove() });
          $(requestRowEl(data)).prependTo($("#requestsTable tbody")).slideDown(300);
        },
        update_control: function(data) {
          data.methods.forEach(function(m) {
            if (
              m.available ||
              (m.error == "Контроль у другого пользователя" &&
                data.controller_id == user_id)
            ) {
              $(`#control .${m.method}`).html(availableMethodEl(m));
            } else {
              $(`#control .${m.method}`).html(unavailableMethodEl(m));
            }
          });
        },
        update_network_config: function (data){
          $("#networkConfig").html(networkTable(data))
        },
        show_spinner: function() {
          $("#spinners:hidden").show(0).delay(1500).hide(0);
        }
      }
    );

    function lightStatusEl(light_on) {
      if (light_on) {
        return "<span class='h4 i fas fa-lightbulb text-warning mr-2'></span><span>Включен</span>";
      } else {
        return "<span class='h4 i far fa-lightbulb mr-2'></span><span>Выключен</span>";
      }
    }

    function lockedStatusEl(is_locked) {
      if (is_locked) {
        return `
          <div class='text-warning'>
            <span class='h4 i fas fa-lock mr-2'></span>
            <span>Включена</span>
          </div>`;
      } else {
        return `
          <div>
            <span class='h4 i fas fa-unlock mr-2'></span>
            <span>Выключена</span>
          </div>`;
      }
    }

    function controllerEl(id, name) {
      let contLink = (!!id && mode == 'admin')
        ? `<a href="/admin/users/${id}">${name}</a>`
        : `<div class="${ !!name ? '' : 'text-danger' }">${!!name ? name : 'Нет информации'}</div>`;

      return `
        <div class="col-4 text-right">
          <strong>Контролер</strong>
        </div>
        <div class="col-8">
          ${contLink}
        </div>`;
    }

    function requestRowEl(data) {
      let searchHref = `/${modePath()}/uspd_devices/${uspd_device_id}/device_requests/${data.id}`
      let cancelHref = `/${modePath()}/uspd_devices/${uspd_device_id}/device_requests/${data.id}/cancel`

      return `
        <tr data-request-id="${data.id}">
          <td class="status-cell">
            <span class="badge ${data.status_badge}">${data.status}</span>
          </td>
          <td>${data.method}</td>
          <td><i class="far ${data.is_continuous ? 'fa-check-square' : 'fa-square'}"></i></td>
          <td>${data.created_at}</td>
          <td class="text-right">
            <a class="btn btn-light btn-sm ml-1" href="${searchHref}">
              <span class="i fas fa-search"></span>
            </a>
            <a data-confirm="Отменить запрос?" class="cancel-link btn btn-outline-warning btn-sm ml-1" href="${cancelHref}">
              <span class="i fas fa-ban"></span>
            </a>
          </td>
        </tr>`;
    }

    function availableMethodEl(data) {
      let methodHref = `/${modePath()}/uspd_devices/${uspd_device_id}/pull_message?method=${data.method}`

      return `
        <div class="col-6 col-md-4">
          <a data-confirm="Отправить ${data.method}?" class="btn btn-block btn-sm btn-outline-primary" href="${methodHref}">
            ${data.method}
          </a>
        </div>
        <div class="col-6 col-md-8">
          <div>${data.name}</div>
        </div>`;
    }

    function unavailableMethodEl(data) {
      return `
        <div class="col-6 col-md-4">
          <button class="btn btn-block btn-sm btn-outline-secondary" disabled>
            ${data.method}
          </a>
        </div>
        <div class="col-6 col-md-8">
          <div>${data.name} (${data.error})</div>
        </div>`;
    }

    function modePath() {
      return mode == 'admin' ? 'admin' : `manage/${manage_id}`;
    }

    function networkTable(data) {
      let result = networkHeader(data.ethernet.value);
      result += networkTablePart(data.ethernet.fields);
      result += networkHeader(data.gsm.value);
      result += networkTablePart(data.gsm.fields);
      return result;
    }

    function networkHeader(value) {
      return `
      <div class='row mb-1'>
        <div class='col-4 text-left'><strong>${value}</strong></div>
      </div>`;
    }

    function networkTablePart(obj) {
      let result = '';
      for (let key in obj) {
        result += `
        <div class='row mb-2'>
          <div class='col-4 text-right'><strong>${key}</strong></div>
          <div class='col-8'>${obj[key]}</div>
        </div>`
      }
      return result;
    }
  }
});
