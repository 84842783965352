import Vue from "vue/dist/vue.esm";
import Calendar from 'v-calendar/lib/components/calendar.umd';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';

Vue.component('calendar', Calendar);
Vue.component('date-picker', DatePicker);

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector("#calendar");
  let today = new Date();

  if (el != null) {
    const app = new Vue({
      el: el,
      components: {
        Calendar,
        DatePicker
      },
      data: function() {
        return {
          mode: 'light',
          size: {
            columns: 3,
            rows: 4
          },
          startPage: {
            month: 1,
            year: today.getFullYear(),
          },
          currentYear: today.getFullYear(),
          pickedDay: today.toLocaleDateString(),
          attrs: [
            {
              key: 'today',
              highlight: "blue",
              dates: today
            }
          ]
        }
      },
      mounted() {
        this.onResize();
        this.$nextTick(() => {
          window.addEventListener('resize', this.onResize);
        })
      },
      methods: {
        onDayClick(day) {
          console.log(process.client);
          this.pickedDay = day.date.toLocaleDateString();
        },
        moveYear(date) {
          this.currentYear = date.year;
        },
        onResize() {
          let ww = window.innerWidth
          if (ww < 576) {
            this.size = { columns: 1, rows: 12 }
          } else if (ww < 992) {
            this.size = { columns: 2, rows: 6 }
          } else {
            this.size = { columns: 3, rows: 4 }
          }
        }
      }
    });
  }
});
