import Vue from "vue/dist/vue.esm";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector("#dateRangeFilter");

  if (el != null) {
    const picked_range = el.dataset.pickedRange;

    const app = new Vue({
      el: el,
      components: {
        DatePicker
      },
      data: function() {
        return {
          range: !!picked_range ? picked_range.split(',') : []
        };
      },
      computed: {
        lang() {
          return {
            defaultLocale: "ru",
            firstDayOfWeek: 1
          };
        }
      }
    });
  }
});
