import Vue from "vue/dist/vue.esm";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";

document.addEventListener("DOMContentLoaded", () => {
  const el = document.querySelector("#exportSchedules");

  if (el != null) {
    const today = new Date();
    const first_day = new Date(today.getFullYear(), 0, 1);
    const last_day = new Date(today.getFullYear(), 11, 31);

    const app = new Vue({
      el: el,
      components: {
        DatePicker
      },
      data: function() {
        return {
          types: ['date', 'period'],
          type: 'date',
          date: this.formatedDate(today),
          range: [
            this.formatedDate(first_day), this.formatedDate(last_day)
          ]
        };
      },
      computed: {
        lang() {
          return {
            defaultLocale: "ru",
            firstDayOfWeek: 1
          };
        }
      },
      methods: {
        formatedDate(date) {
          let month = date.getMonth() + 1;
          let day = date.getDate();
          return [
            date.getFullYear(), month > 9 ? month : `0${month}`, day > 9 ? day : `0${day}`
          ].join('-');
        }
      }
    });
  }
});
